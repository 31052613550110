<template>
    <div class="lineChart" :class="'lineChart-' + this.deviceId + '-' + this.chartName">
    </div>
</template>

<script>
import axios from '../../middleware/axios'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

export default {
    name: 'LineChartWithLegend',
    props: ['deviceId', 'chartName', 'legendName'],
    data() {
        return {
            lineChart: null,
        }
    },
    mounted() {
        console.log(this.deviceId)
        this.lineChart = am4core.create("lineChart-" + this.deviceId + '-' + this.chartName, am4charts.XYChart);
        this.lineChart.paddingRight = 20;
        this.lineChart.data = [];

        let sdateAxis = this.lineChart.xAxes.push(new am4charts.DateAxis());
        sdateAxis.tooltipDateFormat = "HH:mm, d MMMM";
        sdateAxis.renderer.labels.template.fontSize = 8
        let svalueAxis = this.lineChart.yAxes.push(new am4charts.ValueAxis());
        svalueAxis.renderer.labels.template.fontSize = 8
        svalueAxis.title.text = this.legendName;

        this.lineChart.cursor = new am4charts.XYCursor();
        this.lineChart.cursor.lineY.opacity = 0;
        this.lineChart.legend = new am4charts.Legend();
        
        sdateAxis.start = 0.0;
        sdateAxis.keepSelection = true;

        const api_url = 'https://solareye.ntx.bg:9091/devices/' + this.deviceId + '/charts/' + this.chartName;

        axios.get( api_url )
        .then(res => {
          console.log(res.data)
          if(res.data) {
            //this.result = res.data
            let chart = this.lineChart
            let legend = this.legendName
            chart.data = this.convertChartData(res.data)

            Object.keys(chart.data[0]).filter(function(item) {
                return item !== 'date'; //filter out non-series value fields
            }).forEach(function(valueField) {
                console.log(valueField)
                //create your series
                var series = chart.series.push(new am4charts.LineSeries());
                series.name = valueField;
                series.tooltipText = legend + " " + valueField + ": [bold]{valueY}[/]";
                series.dataFields.dateX = 'date';
                series.dataFields.valueY = valueField;
            });
          }
        })
        .catch(err => {
          console.log(err);        
        })
    },
    methods: {
        convertChartData(data) {
            let t = [];
            for(const {date, ...rest} of data) {
                let t1 = {
                    date: new Date(date),
                    ...rest
                }
                t.push(t1);
            }
            console.log(t);
            return t;
        },
    }
}
</script>

<style scoped>
.lineChart {
    height: 240px;
    width: 100%;
}
</style>