<template>
    <div class="charts pt-2">
        <div>
            <h3 class="float-left">Графики </h3>
            <div class="float-right">
                <b-button  size="sm" variant="outline-danger" @click="$router.push({name: 'devices'})">
                    <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon> Назад
                </b-button>
            </div>
            <div class="clearfix"></div>
        </div>
        <b-list-group >
            <b-list-group-item href="#" class="flex-column align-items-start mt-2">
                <b-row class="mt-2 align-items-center justify-content-center">
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                        <h5 class="ml-auto mr-2">Температура на инвертора</h5>
                        <LineChartWithLegend :deviceId=this.id chartName="cabinet_temperature" legendName="Градуса C" />
                    </b-col>
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">    
                        <h5 class="ml-auto mr-2">Ефективност на инвертора</h5>
                        <LineChartWithLegend :deviceId=this.id chartName="inverter_efficiency" legendName="Ефективност %" />
                    </b-col>
                </b-row>
            </b-list-group-item>
            <b-list-group-item href="#" class="flex-column align-items-start mt-2">
                <b-row class="mt-2 align-items-center justify-content-center">
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
                        <h5 class="ml-auto mr-2">Ток по фазите</h5>
                        <MultiLineChartWithLegend :deviceId=this.id chartName="i" legendName="Ток (A)" />
                    </b-col>
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
                        <h5 class="ml-auto mr-2">Напрежение по фазите</h5>
                        <MultiLineChartWithLegend :deviceId=this.id chartName="us" legendName="Напрежение (V)" />
                    </b-col>
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
                        <h5 class="ml-auto mr-2">Напрежение между фазите</h5>
                        <MultiLineChartWithLegend :deviceId=this.id chartName="ut" legendName="Напрежение (V)" />
                    </b-col>
                </b-row>
            </b-list-group-item>
            <b-list-group-item href="#" class="flex-column align-items-start mt-2">
                <b-row class="mt-2 align-items-center justify-content-center">
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                        <h5 class="ml-auto mr-2">Напрежение по стринговете</h5>
                        <MultiLineChartWithLegend :deviceId=this.id chartName="pv_voltage" legendName="Напрежение (V)" />
                    </b-col>
                    <b-col class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                        <h5 class="ml-auto mr-2">Ток по стринговете</h5>
                        <MultiLineChartWithLegend :deviceId=this.id chartName="pv_current" legendName="Ток (A)" />
                    </b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group>
        <b-button class="mt-2" size="sm" variant="outline-danger" @click="$router.push({name: 'devices'})">
            <b-icon icon="arrow-left-circle-fill" font-scale="1"></b-icon> Назад
        </b-button>
    </div>
</template>

<script>
import LineChartWithLegend from '../charts/LineChartWithLegend.vue'
import MultiLineChartWithLegend from '../charts/MultiLineChartWithLegend.vue'
// import moment from 'moment'

export default {
    name: "Charts",
    props: ['id'],
    components: {
        LineChartWithLegend,
        MultiLineChartWithLegend
    }
}
</script>

<style scoped>
h4 {
    font-family: 'Avenir LT Pro', Helvetica, Arial, sans-serif;
}
</style>